module.exports = [{
      plugin: require('/Users/james/actual/actual-site/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"]},
    },{
      plugin: require('/Users/james/actual/actual-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-112676575-2"},
    },{
      plugin: require('/Users/james/actual/actual-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
